@import "bufferbloat_test_component";
@import "banner";

$background-light-blue: #43939B;
$background-dark-blue: #285B62;

.your-connection-is-good-for {
  @include banner-flex-basis;
  @media (min-width: $width-boostrap-md) {
    margin-bottom: 0;
  }
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: linear-gradient(to right, $background-dark-blue, $background-light-blue);
  padding: 20px 15px;
  @media (min-width: $width-boostrap-md) {
    padding: 20px 25px 25px;
  }
  color: white;
  .header {
    text-align: center;
    h4 {
      @include banner-header;
      margin: 0;
    }
  }
  .good-for, .not-good-for {
    img {
      height: 40px;
      @media (min-width: $width-boostrap-md) {
        height: 35px;
      }
      @media (min-width: $width-boostrap-xl) {
        height: 50px;
      }
    }
    @include banner-paragraph-font;
    p {
      margin: 0;
    }
    display: flex;
    margin-top: 10px;
    align-items: center;
    span {
      margin-right: 10px;
    }
  }

  .read-more {
    text-align: center;
    text-decoration: underline;
    font-size: 17px;
    @media (min-width: $width-boostrap-md) {
      font-size: 15px;
    }
    @media (min-width: $width-boostrap-lg) {
      font-size: 17px;
      margin-top: 5px;
    }
  }
}