@import "component_styles";
@import "theme";


.before-you-start {
  background-color: $background-secondary;
  padding: 10px;
  .hide-if-small {
    display: none;
  }
  @media (min-width: $width-boostrap-md) {
    background-color: white;
    padding: 25px;
    flex-grow: 1;
    .hide-if-small {
      display: block;
    }
  }
  .numbered-point {
    font-weight: bold;
    margin-top: 26px;
    margin-bottom: 10px;
    line-height: 1.2;
    font-size: 20px;
    @media (min-width: $width-boostrap-md) {
      font-size: 17px;
    }
    @media (min-width: $width-boostrap-lg) {
      font-size: 20px;
    }
  }
}
 