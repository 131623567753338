@import "bufferbloat_test_component";

$dark-blue: #024E79;
$light-blue: #0072B0;

@mixin padding-horizontal-none {
  padding-left: 0;
  padding-right: 0;
}

@mixin set-padding-horizontal-none {
  @media (min-width: $width-boostrap-md) {
    @include padding-horizontal-none;
  }
  @media (min-width: $width-boostrap-lg) {
    @include padding-horizontal-none;
  }
}

.banner.speed-banner {
  @include set-padding-horizontal-none;
}

.speed-banner {
  flex-grow: 5;
  margin-bottom: 10px;
  background-image: linear-gradient(to right, $dark-blue, $light-blue);
  min-height: 135px;
  @include banner-top-margin;
  @media (min-width: $width-boostrap-md) {
    margin-bottom: 0;
  }
  @include banner-flex-basis;
  .callout-container {
    .callout.speed-callout {
      p {
        font-size: 37px;
        @media (min-width: $width-boostrap-lg) {
          margin-top: -5px;
          font-size: 60px;
        }
        @media (min-width: $width-boostrap-xl) {
          margin-top: 10px;
        }
        &.warming-up {
          font-size: 20px;
          margin-top: 13px;
          @media (min-width: $width-boostrap-md) {
            font-size: 25px;
            margin-top: 12px;
          }
          @media (min-width: $width-boostrap-lg) {
            margin-top: 18px;
            font-size: 30px;
          }
          @media (min-width: $width-boostrap-xl) {
            margin-top: 25px;
            font-size: 40px;
          }
        }
      }
    }
  }
}


