@import "component_styles";
@import "tooltip_wrapper";

$dark-gellow: #43530E;
$light-gellow: #828D04;

.download-test-data-component {
  display: none;
  @media (min-width: $width-boostrap-md) {
    display: initial;
  }
  @include set-tooltip-width(150px);
  button  {
    &:hover, &:focus {
      cursor: pointer;
      color: white;
    }
    background-image: linear-gradient(to right, $dark-gellow, $light-gellow);
    color: white;
    padding: 9px 12px;
    border-radius: 5px;
    line-height: 0;
  }
}
