@import "bufferbloat_test_component";

.real-world-impact-banner {
  flex-grow: 5;
  padding-bottom: 15px;
  table {
    margin-top: 15px;
    color: white;
    width: 100%;
    font-weight: 400;

    font-size: 17px;
    @media (min-width: $width-boostrap-md) {
      font-size: 15px;
    }
    @media (min-width: $width-boostrap-lg) {
      font-size: 16px;
    }
    @media (min-width: $width-boostrap-xl) {
      font-size: 17px;
    }

    .header, .indicator {
      text-align: center;
    }
    .header-row {
      border-bottom: 3px solid rgba(255, 255, 255, 0.55);
    }
    .header {
      font-weight: 400;
      font-size: 15px;
      max-width: 80px;
      line-height: 1.3;
      padding-bottom: 4px;
      
      &:nth-child(2) {
        padding-right: 10px;
      }
      @media (min-width: $width-boostrap-sm) {
        max-width: none;
        &:nth-child(2) {
          padding-right: 0;
        }
      }
      @media (min-width: $width-boostrap-md) {
        font-size: 13px;
        max-width: 80px;
        &:nth-child(2) {
          padding-right: 10px;
        }
      }
      @media (min-width: $width-boostrap-lg) {
        max-width: 120px;
        font-size: 15px;
        &:nth-child(2) {
          padding-right: 0;
        }
      }
      @media (min-width: $width-boostrap-xl) {
        max-width: none;
      }
    }
    .indicator {
      font-weight: 400;
    }
    .body-row {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      &:last-child {
        border-bottom: none;
      }
    }
  }

  .read-more {
    text-align: center;
    text-decoration: underline;
    font-size: 17px;
    margin-top: 15px;
    a {
      color: white;
    }
  }
}
