@import "theme";

// common variables
$primary-medium: #005888;
$primary-dark: #024e79;
$main-test-card-white: #f9f9f9;
$error-red: #980E1F;
$success-green: #447400;

$width-iphone-se: 320px;
$width-wbb-xs: 400px;
$width-boostrap-sm: 576px;
$width-boostrap-md: 768px;
$width-boostrap-lg: 992px;
$width-boostrap-xl: 1200px;
$bootstrap-col-padding: 15px;


// button styles
button.btn-primary {
  background-color: $primary-medium;
  border-color: $primary-medium;
}

button.secondary {
  transition-duration: 0.3s;
  background-color: $secondary-light;
  color: white;
  &:hover, &:focus, &:active, &:focus:active{
    background-color: $secondary-dark;
    color: white;
  }
}

.flex-container {
  display: flex;
}
