@import "bufferbloat_test_component";
@import "theme";


.test-walkthrough {
  &.small-screen {
    margin-top: 15px;
    padding: 15px;
  }

  &.medium-and-larger-screen {
    display: none;
    padding: 20px
  }

  @media (min-width: $width-boostrap-md) {
    &.small-screen {
      display: none;
    }
    &.medium-and-larger-screen {
      display: initial;
    }
  }

  @include banner-right-margin;
  .second-header {
    margin-top: 20px;
  }
  flex-grow: 5;
  flex-basis: 300px;
  .explanation {
    span {
      transition-duration: 0.3s;
    }
  }
  .highlighted {
    color: $secondary-light;
    font-weight: bold;
  }
}


