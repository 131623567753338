$light-purple: #6F0E57;
$dark-purple: #320A28;

$secondary-light: $light-purple;
$secondary-dark: $dark-purple;

$secondary-left-to-right: linear-gradient(to right, $secondary-dark, $secondary-light);

$background-primary: white;
$background-secondary: #F6F6F6;

$background-secondary-darker: #DFDFDF;
