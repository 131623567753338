@import "theme";
@import "component_styles";

$frame-container-border-radius: 20px;

@mixin banner-font-size {
  font-size: 17px;
  @media (min-width: $width-boostrap-md) {
    font-size: 15px;
  }
  @media (min-width: $width-boostrap-lg) {
    font-size: 16px;
  }
  @media (min-width: $width-boostrap-xl) {
    font-size: 20px;
  }
}

@mixin banner-paragraph-font {
  p {
    @include banner-font-size
  }
}

@mixin top-banner-width {
  @media (min-width: $width-boostrap-md) {
    height: 180px;
  }
  @media (min-width: $width-boostrap-lg) {
    height: 220px;
  }
  @media (min-width: $width-boostrap-xl) {
    height: 240px;
  }
}

@mixin top-banner-flex-basis {
  //flex-basis: 250px;
  //@media (min-width: $width-boostrap-md) {
  //  flex-basis: 210px;
  //}
  //@media (min-width: $width-boostrap-lg) {
  //  flex-basis: 258px;
  //}
  //@media (min-width: $width-boostrap-xl) {
  //  flex-basis: 275px;
  //}
}


@mixin banner-flex-basis {
  //@media (min-width: $width-boostrap-md) {
  //  flex-basis: 210px;
  //}
  //@media (min-width: $width-boostrap-lg) {
  //  flex-basis: 258px;
  //}
  //@media (min-width: $width-boostrap-xl) {
  //  flex-basis: 275px;
  //}
}

@mixin banner-right-margin {
  @media (min-width: $width-boostrap-md) {
    margin-right: 10px;
  }
  @media (min-width: $width-boostrap-lg) {
    margin-right: 15px;
  }
}

@mixin banner-top-margin {
  @media (min-width: $width-boostrap-lg) {
    margin-top: 15px;
  }
  margin-top: 10px;
}

@mixin banner-bottom-margin {
  @media (min-width: $width-boostrap-lg) {
    margin-bottom: 15px;
  }
  margin-bottom: 10px;
}

.bufferbloat-test-component {
  &.container {
    padding: 0;
  }
  &.test-done {
    margin-bottom: 35px;
    @media (min-width: $width-boostrap-md) {
      margin-bottom: 35px;
    }
}
  @media (min-width: $width-boostrap-md) {
    margin-bottom: 35px;
  }
  .frame-container {
    margin: 0 5px;
    .top-strip {
      height: $frame-container-border-radius;
      border-top-right-radius: $frame-container-border-radius;
      border-top-left-radius: $frame-container-border-radius;
      background: $secondary-left-to-right
    }
    .body {
      .top-container {
        @media (min-width: $width-boostrap-md) {
          flex-direction: row;
        }
        flex-direction: column-reverse;
      }
      background-color: $background-secondary;
      padding: 10px 0 15px 0;
      @media (min-width: $width-boostrap-md) {
        padding: 15px 15px 20px 15px;
      }
      align-items: stretch;
      .start-test-container {
        text-align: center;
        button {
          font-weight: 600;
          padding: 15px 50px;
          box-shadow: 0 7px 38px 0 rgba(0,0,0,0.18);
          border-radius: 13px;
          font-size: 32px;
        }
        margin-top: 15px;
        @media (min-width: $width-boostrap-md) {
          margin-top: 25px;
        }
      }
    }
  }
}
