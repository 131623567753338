@import "banner";
@import "bufferbloat_test_component";
@import "component_styles";

$dark-gellow: #43530E;
$light-gellow: #828D04;
$default-padding: 20px;

.latency-banner {
  transition-duration: 0.3s;
  background-image: linear-gradient(to right, $dark-gellow, $light-gellow);
  @include top-banner-width;
  @media (min-width: $width-boostrap-md) {
    padding: 20px 10px 20px 10px;
  }
  @media (min-width: $width-boostrap-lg) {
    padding: $default-padding;
  }
}