@import "theme";
@import "component_styles";


.test-title {
  text-align: center;
  @media (min-width: $width-boostrap-md) {
    margin-top: 45px;
    margin-bottom: 30px;
  }
  @media (min-width: $width-boostrap-lg) {
    margin-top: 65px;
    margin-bottom: 50px;
  }
  .test-title-medium-or-larger, .test-title-small-screen {
    background-image: $secondary-left-to-right;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h1 {
    font-weight: 600;
    line-height: 1.4;
  }
}

.test-title-medium-or-larger {
  font-size: 40px;
  letter-spacing: 0;
  @media (min-width: $width-boostrap-md) {
    display: initial;
  }
  @media (min-width: $width-boostrap-lg) {
    font-size: 50px;
  }
  display: none;
}

.test-title-small-screen {
  font-size: 33px;
  margin: 25px 0;
  &.is-prefixed {
    margin: 5px 0 15px 0;
    font-size: 28px;
  }
  .prefix {
    font-size: 15px;
  }
  @media (min-width: $width-boostrap-md) {
    display: none;
  }
}
