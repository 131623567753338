//@import "~fontsource-titillium-web/200-normal.css";
//@import "~fontsource-titillium-web/400-normal.css";
//@import "~fontsource-titillium-web/600-normal.css";
@import "shopify_styles";

* {
  scroll-behavior: smooth;
  -webkit-font-smoothing: subpixel-antialiased;
}

