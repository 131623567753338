@import "component_styles";

$default-padding: 25px;

@mixin banner-header {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 700;
  letter-spacing: 3px;
  font-size: 18px;
  @media (min-width: $width-boostrap-lg) {
    font-size: 24px;
  }
}

.banner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 5px 10px 5px;
  @media (min-width: $width-boostrap-md) {
    padding: 20px 10px 20px 10px;
  }
  @media (min-width: $width-boostrap-lg) {
    padding: $default-padding;
  }
  >h3 {
    flex-grow: 1;
    @include banner-header;
    margin-top: 0;
    margin-bottom: 15px;
    @media (min-width: $width-boostrap-md) {
      margin-bottom: 20px;
    }
  }
  color: white;
  text-align: center;
  .callout-container {
    display: flex;
    flex-grow: 2;
    .callout {
      flex-grow: 1;
      flex-basis: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      &:last-child {
        border-right: none;
      }
      border-right: 1px solid rgba(255, 255, 255, 0.3);
      h4 {
        margin-top: 0;
        font-weight: normal;
        font-size: 13px;
        flex-grow: 1;
        @media (min-width: $width-boostrap-lg) {
          font-size: 17px;
        }
        @media (min-width: $width-boostrap-xl) {
          font-size: 20px;
        }
      }
      p {
        white-space: nowrap;
        flex-grow: 2;
        margin-bottom: 0;
        font-weight: 400;
        font-size: 29px;
        span {
          font-weight: 400;
          font-size: 15px;
        }
        @media (min-width: $width-wbb-xs) {
          font-size: 30px;
        }
        @media (min-width: $width-boostrap-md) {
          font-size: 30px;
          span {
            font-size: 20px;
          }
        }
        @media (min-width: $width-boostrap-lg) {
          font-size: 48px;
        }
        &.is-big {
          @media (min-width: $width-boostrap-md) {
            margin-top: 5px;
            font-size: 25px;
          }
          @media (min-width: $width-boostrap-lg) {
            margin-top: 3px;
            font-size: 43px;
          }
        }
      }
    }
  }
}
