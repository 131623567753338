@import "bufferbloat_test_component";

$tooltip-background: #373737;
$tooltip-width : 300px;
$triangle-size: 10px;

@mixin set-tooltip-width($width) {
  .tooltip-wrapper {
    .tooltip-text {
      @include set-width($width)
    }
  }
}

@mixin set-width($width) {
  width: $width;
  margin-left: - $width / 2;
}

.tooltip-wrapper {
  position: relative;
  display: inline-block;
  &:hover {
    .tooltip-text {
      visibility: visible;
    }
  }
  .tooltip-text {
    display: none;
    @media (min-width: $width-boostrap-md) {
      display: inline-block;
    }
    visibility: hidden;
    background-color: $tooltip-background;
    color: #fff;
    text-align: center;
    padding: 10px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: calc(100% + #{$triangle-size});
    left: 50%;
    @include set-width($tooltip-width);
    &::after {
      content: " ";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -$triangle-size;
      border-width: $triangle-size;
      border-style: solid;
      border-color: $tooltip-background transparent transparent transparent;
    }
  }
}
