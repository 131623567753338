@import "bufferbloat_test_component";


.card-component {
  background-color: white;
  padding: 25px;
  p {
    font-size: 19px;
    @media (min-width: $width-boostrap-md) {
      font-size: 14px;
    }
    @media (min-width: $width-boostrap-lg) {
      font-size: 16px;
    }
    @media (min-width: $width-boostrap-xl) {
      font-size: 18px;
    }
  }
  .underlined-header {
    h3 {
      font-size: 24px;
      @media (min-width: $width-boostrap-md) {
        font-size: 18px;
      }
      @media (min-width: $width-boostrap-lg) {
        font-size: 26px;
      }
    }
  }
  .intro-text {
    margin-bottom:20px;
    
    p {
      color: $secondary-light;
      font-size: 19px;
      strong {
        font-size: 20px;
      }

      @media (min-width: $width-boostrap-md) {
        font-size: 18px;
        strong {
          font-size: 19px;
        }
      }
      @media (min-width: $width-boostrap-lg) {
        font-size: 19px;
        strong {
          font-size: 20px;
        }
      }
      @media (min-width: $width-boostrap-xl) {
        font-size: 20px;
        strong {
          font-size: 21px;
        }
      }
    }
  }
}
