@import "component_styles";


.test-detail-view {
  flex-direction: column;
  display: flex;
  flex-grow: 6;

  @media (min-width: $width-boostrap-md) {
    flex-basis: 300px;
  }
}