@import "bufferbloat_test_component";
@import "banner";
@import "theme";

$background-light-purple: #6F0E57;
$background-dark-purple: #420834;


.bufferbloat-grade-component {
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  justify-content: space-between;
  background-image: $secondary-left-to-right;
  color: white;
  padding: 15px 0 15px 0;
  text-align: center;
  @include top-banner-width;
  @media (min-width: $width-boostrap-md) {
    padding: 25px 25px 15px;
  }
  @include banner-bottom-margin;
  .grade-header {
    h4 {
      @include banner-header;
      margin: 0;
    }
  }
  .grade-value {
    h1 {
      margin: 10px;
      font-size: 72px;
      font-weight: 700;
      @media (min-width: $width-boostrap-md) {
        margin: 0px;
        //font-size: 60px;
      }
      @media (min-width: $width-boostrap-lg) {
        font-size: 80px;
      }
    }
  }
  @include banner-paragraph-font;
  p {
    margin-bottom: 5px;
    @media (min-width: $width-boostrap-md) {
      margin: 0;
    }
    @media (min-width: $width-boostrap-lg) {
      margin-bottom: 5px;
    }
  }
  a {
    color: white;
    text-decoration: underline;
  }
}