@import "component_styles";


.statistics-view {
  margin-top: 20px;
  padding: 0 15px 0 15px;
  @media (min-width: $width-boostrap-sm) {
    padding: 0 ;
  }
}
