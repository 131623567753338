@import "component_styles";
@import "theme";

$button-color-dark: #5D5D5D;
$border-color-dark: #979797;


.no-results {
  background: $background-secondary;
  height: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

$spacer: 5px;

@mixin insert-spacer {
  margin-right: 5px;
  @media (min-width: $width-boostrap-md) {
    margin-right: 15px;
  }
}

#share-results {
  background: $background-secondary;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 5px 10px 20px 10px;
  @media (min-width: $width-boostrap-sm) {
    background: $background-secondary-darker;
    padding: 15px 20px;
  }

  .form-inline {
    margin-bottom: 0;
  }

  .form-group {
    display: flex;
    align-items: center;
    .control-label {
      font-size: 14px;
      white-space: nowrap;
      @include insert-spacer;
      span {
        display: none;
        @media (min-width: $width-boostrap-md) {
          display: initial;
        }
      }
    }
    #shareResults {
      background-color: white;
      flex-grow: 1;
      margin-right: 5px;
      @media (min-width: $width-boostrap-md) {
        flex-grow: initial;
        width: 40%;
      }
      @media (min-width: $width-boostrap-lg) {
        flex-grow: initial;
        width: 50%;
      }
    }
    .copy-button {
      @media (min-width: $width-boostrap-md) {
        margin-right: $spacer;
      }
      background: #005888;
      border-radius: 5px;
      font-weight: bold;
      color: white;
      img {
        margin-left: 5px;
      }
    }
  }
  .test-again {
    button {
      padding: 7px 20px;
      background: $secondary-light;
      border-radius: 7px;
      border: none;
      font-size: 18px;
      color: white;
      font-weight: 600;
    }
    &.big-screen {
      margin-left: auto;
      display: none;
      @media (min-width: $width-boostrap-md) {
        display: initial;
      }
      border-radius: 7px;
      font-size: 18px;
    };
    &.small-screen {
      text-align: center;
      button {
        padding: 10px 30px;
        box-shadow: 0 7px 38px 0 rgba(0,0,0,0.18);
        border-radius: 13px;
        font-size: 18px;
      }
      display: block;
      @media (min-width: $width-boostrap-md) {
        display: none;
      }
    }
  }
}
