@import "component_styles";

@mixin latency-stage-margin {
  @media (min-width: $width-boostrap-sm) {
    margin: 0 20px 0 20px;
  }
}

.latency-stage-report {
  display: flex;
  padding: 10px 10px 0 10px;
  @media (min-width: $width-boostrap-sm) {
    padding: 10px 0 0 0;
  }
  @include latency-stage-margin;
  .box-plot-container {
    flex: 5;
    @media (min-width: $width-boostrap-sm) {
      margin-right: 10px;
    }
    @media (min-width: $width-boostrap-lg) {
      margin-right: 0;
      flex: 8;
      padding: 10px 0 0 0;
    }
    .warming-up {
      margin-left: 25px;
      margin-top: 13px;
    }
  }
  .stage-latency-stats {
    .row {
      margin: 0;
      .col-sm-6 {
        padding: 0;
      }
    }
    flex: 3;
    padding-bottom: 15px;
    @media (min-width: $width-boostrap-lg) {
      padding-top: 10px;
    }
    display: none;
    @media (min-width: $width-boostrap-md) {
      display: block;
    }
    font-size: 12px;
  }
  > div:nth-last-child(3) {
    white-space: nowrap;
  }
  .title {
    flex: 1;
    max-width: 55px;
    @media (min-width: $width-boostrap-sm) {
      max-width: 70px;
    }
    @media (min-width: $width-boostrap-md) {
      max-width: 70px;
    }
    @media (min-width: $width-boostrap-lg) {
      max-width: 90px;
    }
    h5 {
      font-size: 12px;
      white-space: nowrap;
      font-weight: 600;
      margin-top: 15px;
      @media (min-width: $width-boostrap-sm) {
        font-size: 15px;
      }
      @media (min-width: $width-boostrap-md) {
        font-size: 15px;
      }
      @media (min-width: $width-boostrap-lg) {
        font-size: 18px;
        margin-top: 20px;
      }
    }
  }
  &:first-child {
    h5 {
      @media (min-width: $width-boostrap-lg) {
        margin-top: 22px;
      }
    }
  }
}

.spacer {
  display: block;
  height: 5px;
  background-color: white;
  margin-bottom: 10px;
  @include latency-stage-margin;
}
