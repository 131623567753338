@import "theme";

.underlined-header {
  h3 {
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }
  .underline {
    width: 60px;
    height: 4px;
    margin-top: 7px;
    background: $secondary-left-to-right;
  }
  margin-bottom: 10px;
}

