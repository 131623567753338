@import "../components/theme";
@import "../components/component_styles";

#root {
  font-family: "Titillium Web";
}

.container {
  max-width: 1200px;
}

.main-page {
  h2 {
    font-weight: 700;
    margin-top: 10px;
    color: #6f0e57;
    font-size: 32px;
    line-height: 140%;
    text-align: center;
    margin-top: 37px;
    @media (max-width: #{$width-boostrap-md - 1}) {
      font-size: 24px;
    }
  }

  &.main-page-affiliate {
    padding: 0;

    p {
      color: #555555;
      text-align: center;
      font-size: 14px;
      line-height: 140%;
    }
  }

  @media (max-width: #{$width-boostrap-md - 1}) {
    &.main-page-affiliate {
      padding: 16px;

      .main-page-products {
        display: flex;
        flex-direction: column;
        padding: 0 28px;

        .main-page-product {
          margin-bottom: 32px;

          .header-link {
            height: inherit;
          }
          
          h4 {
            min-height: inherit;
            margin: 0;
          }
        }

      }

    }
  }

  @media (min-width: $width-boostrap-md) and (max-width: $width-boostrap-xl) {
    .main-page-products {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 32px 24px;
      max-width: 590px;
      margin: 0 auto;
    }
  }

  @media (min-width: #{$width-boostrap-xl + 1}) {
    .main-page-products {
      display: flex;
      justify-content: space-between;

      .main-page-product {
        width: 24%;
      }
    }
  }

  .main-page-products {
    margin-top: 25px;

    .main-page-product {
      min-height: 463px;
      border-radius: 8px;
      border: 1px solid #eeddee;
      box-shadow: 0px 10px 20px 0px #00000014;
      padding: 24px;

      &:hover {
        box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.12);

        .img-container img {
          width: 110%;
        }
      }

      h4 {
        font-size: 16px;
        line-height: 140%;
        color: #6f0e57;
        font-weight: 600;
        min-height: 44px;
      }

      p {
        font-size: 15px;
      }

      h4,
      p {
        text-align: left;
        margin-top: 24px;
      }

      .img-container {
        min-height: 185px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          margin: 0 auto;
          width: 93%;
        }
      }

      a {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        height: 48px;
        border-radius: 4px;
        text-decoration: none;
        color: white;
        margin-top: 16px;

        &.header-link {
          justify-content: left;
        }

        &.view-amazon-button {
          background-color: #058acf;

          &:hover {
            background-color: #0384c5;
          }
        }

        img {
          margin-right: 12px;
          width: 24px;
        }

        span {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }

  &.main-page-description {
    .FAQContainer {
      max-width: 600px;
      @media (min-width: $width-boostrap-md) {
        margin: 0 auto 30px auto;

        .FAQAnswer {
          font-size: 21px;
          margin-bottom: 35px;
        }
      }
      @media (min-width: $width-boostrap-lg) {
        max-width: 700px;
        margin: 80px auto 30px auto;
        .FAQAnswer {
          margin-top: 20px;
        }
      }
      .FAQ {
        margin-top: 30px;
        font-size: 19px;

        @media (min-width: $width-boostrap-lg) {
          font-size: 21px;
        }
        &:last-child {
          margin-bottom: 80px;
        }

        div > ul > li {
          margin-top: 10px;
        }

        .question {
          &:hover {
            cursor: pointer;
            color: $secondary-light;
          }
          p {
            display: flex;
            justify-content: space-between;
            span {
              font-weight: 600;
            }
            img {
              transition: 0.3s;
              &.not-shown {
                transform: rotateX(0deg);
              }
              &.shown {
                transform: rotateX(180deg);
              }
            }
          }
        }
      }
    }
  }
}
