@import "bufferbloat_test_component";
@import "component_styles";

.test-report-component {
  flex-direction: column;
  flex-grow: 6;
  @media (min-width: $width-boostrap-md) {
    flex-basis: 300px;
  }
  display: flex;
  @include banner-right-margin;
}